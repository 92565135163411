export const buttonStyles = {
  baseStyle: {
    fontFamily: 'medium',
    borderRadius: '8px',
    bg: '#f00 !important',
    _focus: {
      boxShadow: 'none',
      outline: 'none'
    }
  },
  sizes: {
    lg: {
      fontSize: { base: '1.8rem', lg: '2.4rem' },
      fontWeight: 'medium',
      px: '3rem',
      py: '4rem'
      // h: { base: '4.8rem', lg: '6.2rem' }
    },
    md: {
      fontSize: { base: '1.4rem', lg: '1.5rem' },
      fontWeight: 'medium',
      px: '3rem',
      py: '2.5rem'
    },
    sm: {
      fontSize: { base: '1.2rem', lg: '1.2rem' },
      fontWeight: 'medium',
      px: '2rem',
      py: '0.8rem'
      // h: { base: '3.2rem' }
    }
  },
  variants: {
    transparent: {
      bg: 'transparent'
    },
    dark: {
      bg: 'brand.bg',
      color: 'backgrounds.white',
      _hover: {
        _disabled: {
          bg: 'brand.bg'
        }
      }
    },
    grey: {
      bg: '#F9FAFB',
      color: '#000',
      _hover: {
        _disabled: {
          bg: 'brand.bg'
        }
      }
    },
    primary: {
      bg: 'brand.primary',
      color: 'white',
      _hover: {
        _disabled: {
          bg: '#B8B6C7',
          color: '#7B788D',
          border: 'none',
          opacity: '1'
        }
      },
      _active: {
        bg: 'rgba(46, 74, 181, 0.12)',
        borderColor: 'brand.purple',
        borderWidth: '1px',
        color: 'brand.purple'
      },
      _disabled: {
        bg: '#B8B6C7',
        color: '#7B788D',
        opacity: '1'
      }
    },
    orangeOutline: {
      bg: 'transparent',
      borderWidth: '1px',
      borderColor: 'brand.orange',
      color: 'brand.orange',
      _active: {
        bg: 'rgba(46, 74, 181, 0.12)'
      },
      _focus: {
        borderWidth: '1px',
        borderColor: 'brand.orange'
      },
      _hover: {
        _disabled: {
          bg: 'backgrounds.white'
        }
      },
      _disabled: {
        color: '#7B788D',
        borderColor: '#B8B6C7',
        opacity: '1'
      }
    },
    darkOutline: {
      bg: 'transparent',
      borderWidth: '1px',
      borderColor: 'brand.bg',
      color: 'brand.bg',
      _active: {
        bg: 'rgba(46, 74, 181, 0)'
      },
      _focus: {
        borderWidth: '1px',
        borderColor: 'brand.bg'
      },
      _hover: {
        _disabled: {
          bg: 'backgrounds.white'
        }
      },
      _disabled: {
        color: '#7B788D',
        borderColor: '#B8B6C7',
        opacity: '1'
      }
    }
  },
  defaultProps: {
    variant: 'primary'
  }
}

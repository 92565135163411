export const radioStyles = {
  baseStyle: {
    control: {
      border: '1px',
      borderColor: 'brand.orange',
      borderRadius: 'full',
      _disabled: {
        opacity: 0.5,
        borderColor: 'brand.orange'
      },
      _checked: {
        borderColor: 'brand.orange',
        bg: 'brand.orange',
        _hover: {
          borderColor: 'brand.orange',
          bg: 'brand.orange'
        },
        _disabled: {
          opacity: 0.5,
          borderColor: 'brand.orange',
          bg: 'brand.orange'
        }
      },
      _focus: {
        boxShadow: 'none',
        outline: 'none'
      }
    },
    label: {
      fontWeight: 'medium',
      color: 'gray.900'
    }
  }
}

import { Global } from '@emotion/react'

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Silka Bold';
        font-display: swap;
        src: url('/fonts/silka-bold.ttf');
      }
      @font-face {
        font-family: 'Silka Medium';
        font-display: swap;
        src: url('/fonts/silka-medium.ttf');
      }
      @font-face {
        font-family: 'Silka Regular';
        font-display: swap;
        src: url('/fonts/silka-regular.ttf');
      }
      @font-face {
  font-family: 'Georgia';
  font-style: normal;
  font-display: swap;
  src: url('./fonts/georgia.ttf') ;
}
      @font-face {
  font-family: "Georgia" ;
  font-style: light;
   font-display: swap;
  src: url('./fonts/Georgia-Bolditalic.ttf') ;
}


      `}
  />
)

export const fonts = {
  heading: "'Silka Regular', sans-serif",
  medium: "'Silka Medium', sans-serif",
  body: "'Silka Regular', sans-serif"
}

export const textStyles = {
  h1: {
    // you can also use responsive styles
    fontSize: ['48px', '72px'],
    fontWeight: '500',
    letterSpacing: '-2%',
    fontFamily: 'Georgia, serif'
  },
  h2: {
    fontSize: ['36px', '48px'],
    fontWeight: 'semibold',
    lineHeight: '110%',
    letterSpacing: '-1%'
  }
}

export const inputStyles = {
  baseStyle: {
    field: {
      fontFamily: 'Silka Regular',
      fontSize: '16px !important',
      py: '1.2rem',
      px: '1.5rem',
      rounded: '6px',
      borderWidth: '1px',
      borderColor: 'transparent',
      bg: '#F0EFFC',
      _placeholder: {
        color: 'text.muted',
        fontFamily: 'medium'
      },
      _invalid: {
        borderColor: 'borders.error'
      },
      _focus: {
        border: '1.5px solid #B9B9DC'
      }
    }
  },
  sizes: {
    lg: {},
    md: {}
  },
  variants: {
    message: {
      border: 'none',
      height: '100%'
    }
  },
  defaultProps: {
    // This is needed to clear the default chakra input styles for whatever reasons
    variant: null,
    size: null
  }
}

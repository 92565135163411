import { extendTheme } from '@chakra-ui/react'
import { buttonStyles, inputStyles, selectStyles } from './components'
import { colors, fonts, textStyles } from './foundations'
import { styles } from './global'

export default extendTheme({
  colors,
  fonts,
  textStyles,
  styles,
  components: {
    Button: buttonStyles,
    Input: inputStyles,
    // Switch: switchStyles
    Select: selectStyles
  }
})

export const styles = {
  global: {
    // GENERAL

    html: {
      fontSize: '62.5%',
      scrollBehavior: 'smooth',
      height: '-webkit-fill-available'
    },

    'iframe[name="pan-text"]': {
      display: 'inline',
      height: '50px'
    },
    'iframe[name="cvv-text"]': {
      display: 'inline',
      height: '15px'
    },
    'iframe[name="pin-text"]': {
      display: 'inline',
      height: '15px'
    },
    body: {
      // color: '#27272E',
      fontSize: '1.4rem',
      minHeight: '-webkit-fill-available',
      msOverflowStyle: 'none' /* IE and Edge */,
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
        width: '0px'
      },
      '::-webkit-scrollbar': {
        display: 'none',
        width: '0px'
      }
    },
    a: {
      _hover: {
        textDecoration: 'none !important'
      }
    },
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
      {
        WebkitBoxShadow: '0 0 0 40px #fff inset !important',
        boxShadow: '0 0 0 40px #fff inset !important'
      },
    '.p-carousel .p-carousel-indicators': {
      padding: '1rem 1rem 1rem 0 !important',
      justifyContent: 'left !important'
    },
    '.p-carousel .p-carousel-indicators .p-carousel-indicator button': {
      borderRadius: '11px !important',
      width: '3em !important'
    },
    // Flutterwave close button
    'close-btn-wrapper': {
      display: 'none !important'
    },
    '.react-datepicker__input-container': {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '40px',
      padding: '10px',
      borderRadius: '6px !important',
      border: '0.5px solid #D2D2EF'
    },
    '.react-datepicker-ignore-onclickoutside': {
      border: 'none'
    },
    'css-5tqhk8': {
      color: '#665BE0'
    },
    'chakra-radio__input': {
      width: '500px',
      height: '500px'
    }
  }
}

'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ChakraProvider } from '@chakra-ui/react'
import { ReactNode, useState } from 'react'
import theme from 'styles/theme'
import { Toaster } from 'react-hot-toast'
import { Fonts } from 'styles/foundations'
import { TitleContext } from 'contexts/TitleContext'
import { ParallaxProvider } from 'react-scroll-parallax'

export default function AppLayout({ children }: { children: ReactNode }) {
  const [queryClient] = useState(() => new QueryClient())
  const [title, setTitle] = useState<string>('')

  return (
    <ChakraProvider theme={theme}>
      <TitleContext.Provider value={{ title, setTitle }}>
        <ParallaxProvider>
          <Fonts />
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
          <Toaster />
        </ParallaxProvider>
      </TitleContext.Provider>
    </ChakraProvider>
  )
}

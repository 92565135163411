import { createContext, useContext } from 'react'
export type TitleContent = {
  title: string
  setTitle: (c: string) => void
}
export const TitleContext = createContext<TitleContent>({
  title: '',
  setTitle: (title: string) => {}
})
export const useTitleContext = () => useContext(TitleContext)
